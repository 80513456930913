import $ from 'jquery';
import 'selectric';
import '@/scripts/helpers/polyfills';
import lazyframe from 'lazyframe';
import { addedLazyLoadImages } from '@/scripts/helpers/lazyload';
import { bindJQuery } from '@/scripts/helpers/library';
import { CLASSES, BREAKPOINTS, KEYCODES } from '@/scripts/helpers/constants';
import 'focus-visible';

const { IS_ACTIVE, IS_OPEN } = CLASSES;
const { TABLET, DESKTOP } = BREAKPOINTS;

const mobileCardsCount = 1;
const tabletCardsCount = 2;
const desktopCardsCount = 3;

let defaultYear = $('.js-calculator-year-button.is-active').data('year') || 2020;
const rates = ['10%', '12%', '22%', '24%', '32%', '35%', '37%'];
let statusSelectValue = 'Single';

const isMobile = () => $(window).width() <= TABLET;
const isTablet = () => $(window).width() >= TABLET && $(window).width() < DESKTOP;
const isDesktop = () => $(window).width() >= DESKTOP;

const howCardInViewport = () => {
  if (isMobile()) {
    return mobileCardsCount;
  }
  if (isTablet()) {
    return tabletCardsCount;
  }
  if (isDesktop()) {
    return desktopCardsCount;
  }
};

const renderSelect = function () {
  const options = window.data[defaultYear][statusSelectValue];
  $('.js-select-income').find('option').remove();
  $('.js-calculator-rate').text(rates[0]);

  for (let i = 0; i < options.length; i += 1) {
    $('.js-select-income').append(`<option data-rate="${rates[i]}">${options[i]}</option>`);
  }

  $('.js-select-income').selectric('refresh');
};

const handleClickButton = function (e) {
  $(this).addClass(IS_ACTIVE).siblings().removeClass(IS_ACTIVE);
  defaultYear = $(this).data('year');
  renderSelect();
};

const handleChangeSelectStatus = function () {
  statusSelectValue = $(this).val();
  renderSelect();
};

const handleChangeIncome = function () {
  $('.js-calculator-rate').text($('.js-select-income').find('option:selected').data('rate'));
};

const initCustomSelectPlugin = () => {
  $('.js-select-status').selectric({
    onChange: handleChangeSelectStatus,
  });

  $('.js-select-income').selectric();
};

const hideShowMore = () => {
  $('.js-search-cards').parent().addClass('hide-showmore');
};

const showShowMore = () => {
  $('.js-search-cards').parent().removeClass('hide-showmore');
};

const hideNotFound = () => {
  $('.js-not-found').addClass('hide');
  $('.js-search-cards').removeClass('hide');
};

const showNotFound = () => {
  $('.js-not-found').removeClass('hide');
  $('.js-search-cards').addClass('hide');
};

const handleSearchForms = (e) => {
  const cardsContainer = $('.js-search-cards');
  const cards = cardsContainer.find('.card');
  const cardsCount = cards.length;
  const inputValue = e && e.target.value.trim().toLowerCase() || $('.js-search-form').val();

  cards.each((_, form) => {
    const title = $(form).find('.card__title')
      .text()
      .trim()
      .toLowerCase();
    const description = $(form).find('.card__description')
      .text()
      .trim()
      .toLowerCase();

    if (title.indexOf(inputValue) === -1 && description.indexOf(inputValue) === -1) {
      $(form).addClass('hide').removeClass('visible');
    } else {
      $(form).removeClass('hide');
    }
  });

  for (let i = 0; i < howCardInViewport(); i++) {
    const visibleCards = $('.js-search-cards .card:not(.hide)');
    $(visibleCards[i]).addClass('visible');
  }

  const hidenCardsCount = cardsContainer.find('.card.hide').length;

  if (cardsCount - hidenCardsCount <= howCardInViewport()) {
    hideShowMore();
  } else {
    showShowMore();
  }

  if (hidenCardsCount === cards.length) {
    showNotFound();
  } else {
    hideNotFound();
  }
};

const changeOrder = function () {
  const cards = $('.js-search-cards');
  const cardCollection = cards.find('.card').clone();

  cards.html('');

  cardCollection.each((_, card) => {
    $(card).removeClass('visible');
    $(card).prependTo(cards);
  });
  handleSearchForms();
  $(this).toggleClass(IS_ACTIVE);
};

const showMore = () => {
  if ($('.js-show-more').length) {
    for (let i = 0; i < howCardInViewport(); i++) {
      const cards = $('.js-search-cards .card');
      $(cards[i]).addClass('visible');
    }

    $('.js-show-more').on('click', function () {
      const buttonText = $(this).find('.section-actions__button-text');
      buttonText.text(buttonText.text() === 'Show more' ? 'Show less' : 'Show more');
      $(this).parent().toggleClass(IS_OPEN);
      $(this).parent().prev().toggleClass(IS_OPEN);
    });
  }
};

$(document).ready(() => {
  addedLazyLoadImages();
  bindJQuery();
  showMore();
  initCustomSelectPlugin();

  $('.js-calculator-year-button').on('click', handleClickButton);
  $('.js-select-status').on('change', handleChangeSelectStatus);
  $('.js-calculate-rate').on('click', handleChangeIncome);
  $('.js-change-order').on('click', changeOrder);
  $('.js-search-form').on('input', handleSearchForms);
  $('.js-search-form').on('keyup', (event) => {
    if (event.keyCode === KEYCODES.ENTER) {
      $('.js-search-form').blur();
    }
  });

  const elements = $('.video-player__lazyframe');

  lazyframe(elements, {
    onAppend: iframe => {
      $(iframe).attr('allow', 'autoplay');
    },
  });
});
